import Topbar from "../components/layout/TopBar"
import SideBar from "../components/layout/SideBar"




const Settings = () => {
   


    return(
        <>
            <Topbar />
            <SideBar />
            <div className="work-area">
                <div className="top-area">
                    <div className="left">
                        <div className="header">Настройки</div>
                    </div>  
                    <div className="right">            
                    </div>
                </div>     
                <div className="content-area"> 
                    <div style={{opacity: '.3', fontSize: '18px'}}>каминг сун ...</div>     
                </div>
            </div>
        </>
    )
    
}

export default Settings