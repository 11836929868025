import { IoTrashOutline }           from "react-icons/io5";
import Modal                        from "../../modals/Modal";
import { useState }                 from "react";
import PrintProject                 from "../PrintProject";
import PrintProjectStatus           from "../PrintProjectStatus";
import { useSelector }              from "react-redux";
import PrintDateTimeNoHour          from "../PrintDateTimeNoHour";




const ButtonRemoveProject = ( props ) => {

    const loginToken                                = useSelector(state => state.loginStore.token);
    const apiSource                                 = useSelector(state => state.configStore.apiSource);
    const apiRemoveProject                          = useSelector(state => state.configStore.apiRemoveProject);
    const [modalStatus, setModalStatus]             = useState(false);

    const onRemoveProject = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, projectID: props.data.id})
        };
        fetch(apiSource + apiRemoveProject, requestOptions)
        .then(response => response.json())
        .then(data => {
            props.needToUpdate(true);
            setModalStatus(false);
        });
    }

    return(
        <>
            <button type="button" className="remove-button"><IoTrashOutline color='var(--text-color-red)' onClick={ () => setModalStatus(true) }/></button>

            {modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div className="table-projects">
                                <div className="txt">Вы хотите удалить проект и все данные по нему?</div>
                                <div className="items">
                                    <div className="item item-head" style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
                                        <div>Проект</div>
                                        <div>Последний статус</div>
                                        <div>Дата начала работ</div>
                                    </div>
                                    <div className="item" style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
                                        <div><PrintProject status={props.data.status} name={props.data.name} /></div>
                                        <div><PrintProjectStatus data={{name: props.data.statusName, text: props.data.statusText, color: props.data.statusColor}} /></div>
                                        <div><PrintDateTimeNoHour value={props.data.startDateTime} /></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    title="Удалить проект"
                    buttons={[
                        { 
                            title: "Удалить",  
                            type: "btn-green", 
                            icon: <IoTrashOutline />, 
                            on: onRemoveProject, 
                            id: 0 
                        }
                    ]}
                />
            
            }
        </>
    )
   
    
}

export default ButtonRemoveProject