import { PiUserCircleCheck } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";

const UserSelect = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiGetActiveUsers = useSelector(state => state.configStore.apiGetActiveUsers);

    const [users, setUsers] = useState([]);
    const [popupStatus, setpopupStatus] = useState(false);
    const [currentValue, setValue] = useState('');

    const left = props.iconSize / 2;
    const pad = props.iconSize * 2;

    const onInputClick = () => {
        setpopupStatus(!popupStatus);
    }

    const onItemClick = ( id, name ) => {
        setpopupStatus(false);
        props.getValue(id, name);
        setValue(name);
    }

    const usersItems = users.map( item => 
        <div className="item" key={item.id} onClick={() => onItemClick(item.id, item.name + " " + item.lastname)}>
            <div className="avatar"><img src={apiSource+item.avatar} /></div>
            <div className="name">{item.name} {item.lastname}</div>
        </div>
    );

    useEffect(()=>{
        if( props.value == '' ) {
            setValue('');
        } 
    }, [props.value]);


    useEffect(()=>{
        setValue(props.currentValue);
    }, [props.currentValue]);

    useEffect(()=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, userTypeID: props.userTypeID })
        };

        fetch(apiSource + apiGetActiveUsers, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUsers(data.data);
        });
    }, []);


    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        if (popupStatus === true) {
            setpopupStatus(false);
        }
    });


    
    return(
        <div ref={outsideAlerterRef}>
            <div className="element-input">
                <div className="icon" style={{left: left}}><PiUserCircleCheck size={props.iconSize}/></div>
                <div className="input input-not-focus" style={{marginLeft: `-${props.iconSize}px`}}>
                    <input 
                        readOnly={true}
                        placeholder={props.placeholder} 
                        type="text" 
                        style={{paddingLeft: `${pad}px`, width: "100%"}} 
                        onClick={onInputClick}
                        value={currentValue}
                    />
                </div>
            </div>
            <div className={popupStatus ? "element-popup" : "element-popup display-none"}>
                <div className="items">
                    {usersItems}
                </div>
            </div>
        </div>
    )
}

export default UserSelect