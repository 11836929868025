import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Lottie from "lottie-react";
import Pizza from "../images/pizza.json";

 
const AccessControl = (props) => {
    
    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiIsAuthorizeUrl = useSelector(state => state.configStore.apiIsAuthorizeUrl);

    const [access, setaccess] = useState(false);
    const [render, setrender] = useState(false);

    useEffect(()=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken })
        };

        fetch(apiSource + apiIsAuthorizeUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            data.isAuthorize ? setaccess(true) : setaccess(false);
            setrender(true);
        });
    }, []);

    return(
        <>
            {render && 
                <>
                    {access && 
                        <>
                            {props.page}
                        </>
                    }

                    {!access && 
                        <div className="access-denied-wrapper">
                            <div>
                                <div className="text">
                                    <Lottie animationData={Pizza} loop={true} />
                                </div>
                                <div className="link">Необходимо <Link to="/login">авторизоваться,</Link></div>
                                <div className="link link-text">поесть и начать работу с CRM</div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default AccessControl;