import { useEffect, useState } from "react";
import { BsCopy } from "react-icons/bs";
import Modal from "../modals/Modal";
import DatePicker from "react-datepicker";
import { GoArrowRight } from "react-icons/go";
import { useSelector } from "react-redux";

const ButtonCopyPeriod = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiCopyPeriod = useSelector(state => state.configStore.apiCopyPeriod);

    const [modalStatus, setModalStatus] = useState(false);

    const [dateFrom, setDateFrom] = useState( new Date() );
    const [dateTo, setDateTo] = useState( new Date() );

    useEffect(()=>{
        setDateFrom(props.currentPeriod);
    }, [props.currentPeriod]);

    useEffect( () => {
        const newDate = new Date(dateFrom);
        newDate.setMonth(newDate.getMonth() + 1);
        setDateTo(newDate);
    }, [dateFrom]);

    const ButtonClick = () => {
        setModalStatus(true);
    }

    const ButtonCopyClick = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, fromDate: dateFrom, toDate:  dateTo})
        };

        fetch(apiSource + apiCopyPeriod, requestOptions)
        .then(response => response.json())
        .then(data => {
            setModalStatus(false);
        });

    }

    return(
        <>  
            <button className="btn-transparent btn-inline" onClick={ButtonClick} >
                <BsCopy />
            </button>

            { modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div className="period-copy-form">
                                <div className="columns headers">
                                    <div>Откуда: </div>
                                    <div></div>
                                    <div>Куда: </div>
                                </div>
                                <div className="columns">
                                    <div className="from">
                                        <DatePicker 
                                            showMonthYearPicker 
                                            dateFormat="MMM yyyy" 
                                            selected={dateFrom}
                                            onChange={(date) => setDateFrom(date)}

                                        />
                                    </div>
                                    <div className="icon">
                                        <GoArrowRight size="24"/>
                                    </div>
                                    <div className="to">
                                        <DatePicker 
                                            showMonthYearPicker 
                                            dateFormat="MMM yyyy" 
                                            selected={dateTo}
                                            onChange={(date) => setDateTo(date)}

                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    title="Скопировать проекты на новый период"
                    buttons={[
                        { 
                            title: "Скопировать",  
                            type: "btn-green", 
                            icon: <BsCopy />, 
                            on: ButtonCopyClick, 
                            id: 0 
                        }
                    ]}
                />
            
            }

        </>
    )
}

export default ButtonCopyPeriod;