import { IoTrashOutline } from "react-icons/io5";
import Modal from "../modals/Modal";
import { useState } from "react";
import PrintProject from "./PrintProject";
import PrintProjectStatus from "./PrintProjectStatus";
import PrintUser from "./PrintUser";
import { useSelector } from "react-redux";


const ButtonRemovePeriod = ( props ) => {
    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiRemovePeriod = useSelector(state => state.configStore.apiRemovePeriod);
    const [modalStatus, setModalStatus] = useState(false);

    const RemoveButtonClick = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, periodID: props.data.id })
        };

        fetch(apiSource + apiRemovePeriod, requestOptions)
        .then(response => response.json())
        .then(data => {
            setModalStatus(false);
            props.needUpdate(true);
        });


    }

    return(
        <>
            <div className="element-button-remove-period">
                <button type="button" onClick={() => setModalStatus(true)}>
                    <IoTrashOutline color='var(--text-color-red)'/>
                </button>
            </div>

            { modalStatus && 

                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div className="period-table">
                                <div className="head">Вы уверены, что хотите удалить проект?</div>
                                <div className="period-table-item period-table-item-remove period-table-item-header">
                                    <div>Проект</div>
                                    <div>Статус</div>
                                    <div>Менеджер</div>
                                    <div>Специалист</div>
                                </div>
                                <div className="period-table-item period-table-item-remove">
                                    <div><PrintProject status={props.data.status} name={props.data.name} /></div>
                                    <div>
                                        <PrintProjectStatus 
                                            data={{
                                                id: props.data.ProjectStatusID,
                                                name: props.data.ProjectStatusName,
                                                text: props.data.ProjectStatusText,
                                                color: props.data.ProjectStatusColor
                                            }} 
                                        />
                                    </div>
                                    <div><PrintUser name={props.data.accountName} lastname={props.data.accountLastName} avatar={apiSource + props.data.accountAvatar}/></div>
                                    <div><PrintUser name={props.data.sppName} lastname={props.data.sppLastName} avatar={apiSource + props.data.sppAvatar}/></div>
                                </div>
                            </div>
                        </>
                    }
                    title="Вы уверены?"
                    buttons={[
                        { 
                            title: "Удалить",  
                            type: "btn-red", 
                            icon: <IoTrashOutline />, 
                            on: RemoveButtonClick, 
                            id: 0
                        }
                    ]}
                />
            }
        </>
        
        
    )
}

export default ButtonRemovePeriod