import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import PrintProject from "./PrintProject"

const ProjectInput = (props) => {
    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiGetProjectsLikeName = useSelector(state => state.configStore.apiGetProjectsLikeName);
    const [projects, setProjects] = useState([]);
    const [nothing, setNothing] = useState(true);
    const [currentValue, setValue] = useState('');

    const left = props.iconSize / 2;
    const pad = props.iconSize * 2;

    const onChange = ( value ) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, query: value, statuses: props.statuses})
        };

        fetch(apiSource + apiGetProjectsLikeName, requestOptions)
        .then(response => response.json())
        .then(data => {
            setProjects(data.projects); 
            if ( data.projects.length > 0 ) {
                setNothing(false);
            }

          
        });

        setValue(value);
   
    }

    const onItemClick = (id, name, status) => {
        setNothing(true);  
        props.getValue(id, name, status);
        setValue(name);
    }

    const projectsItems = projects.map(item => 
        <div className="item" key={item.id} onClick={() => onItemClick(item.id, item.name, item.status)}>
            <PrintProject name={item.name} status={item.status} />
        </div>
    );

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        if (nothing === false) {
            setNothing(true);
        }
    });

    return(
        <div ref={outsideAlerterRef}>
            <div className="element-input">
                <div className="icon" style={{left: left}}><CiSearch size={props.iconSize}/></div>
                <div className="input" style={{marginLeft: `-${props.iconSize}px`}}>
                    <input 
                        placeholder={props.placeholder} 
                        type="text" 
                        style={{paddingLeft: `${pad}px`, width: "100%"}}
                        onChange={e => onChange(e.target.value)} 
                        value={currentValue}
                    />
                </div>
            </div>
            <div className={nothing ? "element-popup display-none" : "element-popup"}>
                <div className="items">
                    {projectsItems}
                </div>
            </div>
        </div>
    )
}

export default ProjectInput