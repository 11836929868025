import { GoPin } from "react-icons/go";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PrintProjectStatus from "./PrintProjectStatus";

const ProjectStatusSelect = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiGetProjectStatuses = useSelector(state => state.configStore.apiGetProjectStatuses)

    useEffect(()=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken })
        };

        fetch(apiSource + apiGetProjectStatuses, requestOptions)
        .then(response => response.json())
        .then(data => {
            setStatuses(data.statuses);
        });
    }, []);
   
    const left = props.iconSize / 2;
    const pad = props.iconSize * 2;

    const [currentValue, setCurrentValue] = useState('');
    const [popupStatus, setpopupStatus] = useState(false);
    const [statuses, setStatuses] = useState([]);

    const onInputClick = () => {
        setpopupStatus(!popupStatus);
    }

    const itemsStatuses = statuses.map( item => 
        <div className="item" key={item.id} onClick={() => itemClick(item.id, item.text)}>
            <PrintProjectStatus data={item}  />
        </div>
    );

    const itemClick = (id, text) => {
        setCurrentValue(text);
        setpopupStatus(false);
        props.getValue(id, text);
    }

    useEffect(()=>{
        if( props.value == '' ) {
            setCurrentValue('');
        } else {
            // setCurrentValue(props.value);
        }
    }, [props.value]);

    useEffect(()=>{
        setCurrentValue(props.currentValue);
    }, [props.currentValue]);

   

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        if (popupStatus === true) {
            setpopupStatus(false);
        }
    });
    
    return(
        <div ref={outsideAlerterRef}>
            <div className="element-input">
                <div className="icon" style={{left: left}}><GoPin size={props.iconSize}/></div>
                <div className="input input-not-focus" style={{marginLeft: `-${props.iconSize}px`}}>
                    <input 
                        readOnly={true}
                        placeholder={props.placeholder} 
                        type="text" 
                        style={{paddingLeft: `${pad}px`, width: "100%"}} 
                        onClick={onInputClick}
                        value={currentValue}
                    />
                </div>
            </div>

            <div className={popupStatus ? "element-popup" : "element-popup display-none"}>
                <div className="items">
                    {itemsStatuses}
                </div>
            </div>

        </div>
    )
}

export default ProjectStatusSelect