import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { setSideBarStatus } from "../../store/config";
import SideBarMenu from "./SideBarMenu";

import { IoHomeOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import { BsFolder2Open } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { BsTelephoneOutbound } from "react-icons/bs";




const SideBar = () => {

    const MainMenu = [
        { to: '/',              icon: <IoHomeOutline           size="20"    />, text: 'Главная',          key: 1 },
        { to: '/projects',      icon: <BsFolder2Open           size="20"    />, text: 'Проекты',          key: 2 },
        { to: '/periods',       icon: <IoCalendarOutline       size="20"    />, text: 'Дебиторка',        key: 3 },
        { to: '/interactions',  icon: <BsTelephoneOutbound     size="20"    />, text: 'Коммуникации',     key: 4 }
    ];

    const SettingsMenu = [
        { to: '/settings',  icon: <CiSettings size="24" />, text: 'Настройки', key: 4 },
    ];

    const sideBarStatus = useSelector(state => state.configStore.sideBarStatus);
    const dispatch = useDispatch();

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        if (sideBarStatus === true) {
            dispatch( setSideBarStatus({sideBarStatus: false}) );
        }
    });

    return(
        <>
            <div className={sideBarStatus ? "side-bar side-bar-left" : "side-bar side-bar-left-no"} ref={outsideAlerterRef}>
                <SideBarMenu links={MainMenu}/>
                <div className="line"></div>
                <SideBarMenu links={SettingsMenu}/>
            </div>
        </>
    )
    
}

export default SideBar;