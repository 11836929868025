import { FaPhoneAlt }                              from "react-icons/fa";
import { BiSolidMessageRounded }                   from "react-icons/bi";
import { IoIosMail }                               from "react-icons/io";
import { useState, useRef, useEffect }             from "react";
import { FaTelegramPlane }                         from "react-icons/fa";
import { IoLogoWhatsapp }                          from "react-icons/io";
import { useSelector }                             from "react-redux";
import { GoCheck }                                 from "react-icons/go";
import { RxCross1 }                                from "react-icons/rx";
import PrintDateTime                               from "../PrintDateTime";




const ActionSelect = (props) => {
    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiUpdateAddInteraction = useSelector(state => state.configStore.apiUpdateAddInteraction);
    const userData = useSelector(state => state.loginStore.userData);
    const apiGetCommentsIteraction = useSelector(state => state.configStore.apiGetCommentsIteraction);
    const apiRemoveCommentInteraction = useSelector(state => state.configStore.apiRemoveCommentInteraction);

   
    const [modalStatus, setModalStatus] = useState(false);
    const [phone, setPhone] = useState( () => { if ( props.action !== undefined && props.action.phone !== undefined && props.action.phone == true ) { return true; } else { return false; } });
    const [telegram, setTelegram] = useState( () => { if ( props.action !== undefined && props.action.telegram !== undefined && props.action.telegram == true ) { return true; } else { return false; } });
    const [whatsapp, setWhatsapp] = useState( () => { if ( props.action !== undefined && props.action.whatsapp !== undefined && props.action.whatsapp == true ) { return true; } else { return false; } });
    const [email, setEmail] = useState( () => { if ( props.action !== undefined && props.action.email !== undefined && props.action.email == true ) { return true; } else { return false; } });
    const [textAreaValue, setTextAreaValue] = useState('');
    const [comments, setComments] = useState([]);
    const [updateComments, setUpdateComments] = useState(false);
    const [topModal, setTopModal] = useState();
    const [leftModal, setLeftModal] = useState();
    const [textAreaHeigh, setTextAreaHeight] = useState(32);
    const [borderColor, setBorderColor] = useState('rgba(0, 0, 0, 0)');

    useEffect(()=> {
        if ( modalStatus ) {

            const queryData = {
                day: props.date.toLocaleString("ru", {year: 'numeric', month: '2-digit', day: '2-digit'}),
                projectID: props.projectID,
                phone: phone,
                telegram: telegram,
                whatsapp: whatsapp,
                email: email,
                text: '',
                userID: ''
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: loginToken, data: queryData })
            };
    
            fetch(apiSource + apiUpdateAddInteraction, requestOptions)
            .then(response => response.json())
            .then(data => {
                props.needUpdate(true);
            });


        }
    }, [phone, telegram, whatsapp, email]);

    const SendComment = () => {
        const queryData = {
            day: props.date.toLocaleString("ru", {year: 'numeric', month: '2-digit', day: '2-digit'}),
            projectID: props.projectID,
            phone: phone,
            telegram: telegram,
            whatsapp: whatsapp,
            email: email,
            text: textAreaValue,
            userID: userData.id
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, data: queryData })
        };

        fetch(apiSource + apiUpdateAddInteraction, requestOptions)
        .then(response => response.json())
        .then(data => {
            setModalStatus(false);
            setTextAreaValue('');
            props.needUpdate(true);
            if ( data.insertComment == true ) {
                setBorderColor('rgba(0, 128, 0, 1)');
            }
        });

    }

    useEffect(()=>{
        if (modalStatus) {
            const element = document.querySelector('.element-action-date-modal');
            let top = element.getBoundingClientRect().top;
            let left = element.getBoundingClientRect().left;
            let width = element.getBoundingClientRect().width;
            if (top < 80 ) {
                setTopModal('50px');
            }
            if ( left + width > window.innerWidth ) {
                setLeftModal(window.innerWidth - width);
            }
        }
    }, [comments, updateComments, modalStatus]);

    useEffect( () => {
        setTextAreaHeight(32);
        if ( modalStatus && props.action !== undefined ) {

            const queryData = {
                actionID: props.action.id
            }
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: loginToken, data: queryData })
            };
    
            fetch(apiSource + apiGetCommentsIteraction, requestOptions)
            .then(response => response.json())
            .then(data => {
                setComments(data.comments);
                setUpdateComments(false);
            });
        }
    }, [modalStatus, updateComments]);

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        setModalStatus(false);
    });

    const CommentsMap = comments.map( item =>
        <div className="comment-item" key={item.id}>
            <div className="avatar"><img src={apiSource + item.UserAvatar} /></div>
            <div className="txt">
                <div className="txt-top">
                    <div className="dat"><PrintDateTime value={item.datetime} size="14"/></div>
                    <div className="del"><button type="button" onClick={() => deleteComment(item.id)}><RxCross1 /></button></div>
                </div>
                <div className="cont">{item.text}</div>
            </div>
        </div>
    );

    const deleteComment = (id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, id: id })
        };

        fetch(apiSource + apiRemoveCommentInteraction, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUpdateComments(true);
            if ( data.countComments === '0' ) {
                setBorderColor('rgba(0, 0, 0, 0)');
            }
        });
    }

    useEffect(() => {
        if (props.action !== undefined) {
            if (props.action.isComments == '1') {
                setBorderColor('rgba(0, 128, 0, 1)');
            }
        }
    }, []);


    return(
        <>
            <div className="element-action-date" onClick={ () => setModalStatus(true) } style={{outline: '1px solid ' + borderColor}}>
                { props.action !== undefined &&
                    <div>
                        <div>{phone && <FaPhoneAlt size="10" color="rgba(255, 255, 255, 1)" />}</div>
                        <div>{(telegram || whatsapp ) && <BiSolidMessageRounded size="10" color="rgba(255, 255, 255, .4)" />}</div>
                        <div>{email && <IoIosMail size="10" color="rgba(255, 255, 255, .4)" />}</div>
                    </div>
                }
            </div>

            { modalStatus && 
                <div className="element-action-date-modal" ref={outsideAlerterRef} style={{top: topModal, left: leftModal}}>
                    <div className="buttons">
                        <div className={phone ? "button-item button-active" : "button-item"}><button type="button" onClick={()=>setPhone(!phone)}><FaPhoneAlt /></button></div>
                        <div className={telegram ? "button-item button-active" : "button-item"}><button type="button" onClick={()=>setTelegram(!telegram)}><FaTelegramPlane /></button></div>
                        <div className={whatsapp ? "button-item button-active" : "button-item"}><button type="button" onClick={()=>setWhatsapp(!whatsapp)}><IoLogoWhatsapp /></button></div>
                        <div className={email ? "button-item button-active" : "button-item"}><button type="button" onClick={()=>setEmail(!email)}><IoIosMail /></button></div>
                    </div>
                    <div className="comment-form">
                        <div className="avatar"><img src={apiSource + userData.avatar} /></div>
                        <div className="text-area">
                            <textarea placeholder="комментарий" onChange={(e)=>setTextAreaValue(e.target.value)} value={textAreaValue} onFocus={()=>setTextAreaHeight(100)} style={{height: textAreaHeigh}}></textarea>
                            <button className={textAreaHeigh > 40 ? "btn-green" : "btn-green display-none"} onClick={SendComment}><GoCheck />Отправить</button>
                        </div>
                    </div>

                    { CommentsMap.length > 0 &&
                        <div className="comments">
                            {CommentsMap}
                        </div>
                    }
                    
                </div>
            }
        </>
        
    )
}

export default ActionSelect