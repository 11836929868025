import { useEffect } from "react";


const IconInput = ( props ) => {
    const left = props.iconSize / 2;
    const pad = props.iconSize * 2;

    const setNativeValue = (element, value) => {
        let lastValue = element.value;
        element.value = value;
        let event = new Event("input", { target: element, bubbles: true });

        // React 16
        let tracker = element._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        element.dispatchEvent(event);
    }

    useEffect(()=>{
        var input = document.getElementById(props.id);
        setNativeValue(input, props.currentValue);
    }, []);

    return(
        <>
            <div className="element-input">
                <div className="icon" style={{left: left}}>{props.icon}</div>
                <div className="input" style={{marginLeft: `-${props.iconSize}px`}}>
                    <input 
                        placeholder={props.placeholder} 
                        style={{paddingLeft: `${pad}px`, width: "100%"}} 
                        onChange={props.onChange} 
                        name={props.name}
                        type={props.type}
                        id={props.id}
                    />
                </div>
            </div>
        </>
    )
}

export default IconInput