import Topbar                                   from "../components/layout/TopBar";
import SideBar                                  from "../components/layout/SideBar";
import { useEffect, useState }                  from "react";
import DatePicker                               from "react-datepicker"
import { registerLocale, setDefaultLocale }     from "react-datepicker";
import { ru }                                   from 'date-fns/locale/ru';
import { useSelector }                          from "react-redux";
import ActionSelect                             from "../components/elements/interactions/ActionSelect";
import PrintProject                             from "../components/elements/PrintProject";
import Lottie                                   from "lottie-react";
import Glass                                    from "../images/wired-gradient-567-french-fries-chips-hover-pinch.json";
import UserSelect                               from "../components/elements/UserSelect";
import { RxReset }                              from "react-icons/rx";








const Interactions = () => {

    registerLocale('ru', ru);
    setDefaultLocale('ru');

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiGetInteractions = useSelector(state => state.configStore.apiGetInteractions);
    const userData = useSelector(state => state.loginStore.userData);

    const [periodDate, setPeriodDate]               = useState( new Date() );
    const [datesState, setDatesState]               = useState([]);
    const [actions, setActions]                     = useState([]);
    const [datesItems, setDatesItems]               = useState([]);
    const [projects, setProjects]                   = useState([]);
    const [needUpdate, setNeedUpdate]               = useState(false);

    const [enableContent, setEnableContent]         = useState(false);

    const [filterAccount, setFilterAccount]         = useState('');

    


    const getLastDay = (date) => {
        return new Date((new Date(date.getFullYear(), date.getMonth() + 1, 1)) - 1);
    }

    useEffect( () => {

        let startDate = new Date(periodDate.getFullYear(), periodDate.getMonth(), 1 );
        let endDate = getLastDay(periodDate);
        let currentDate = new Date( startDate );
        let dates = [];
      
        while ( currentDate <= endDate ) {
            dates.push( new Date(currentDate) );
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const weeks = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];;
        const datesItemsLocal = dates.map( item => 
            <div className="date" key={item.getDate()}>
                <div>
                    <div className="digit">{item.getDate()}</div>
                    <div className="week">{weeks[item.getDay()]}</div>
                </div>
            </div>
        );

        setDatesItems(datesItemsLocal);

        let filterAccountUserType;

        if (userData.userTypeID == '1' ) {
            filterAccountUserType = filterAccount;
        } else {
            filterAccountUserType = userData.id;
        }

        const SendData = {
            start: startDate.toLocaleString("ru", {year: 'numeric', month: 'numeric', day: '2-digit', hour: "2-digit", minute: '2-digit', second: '2-digit'}),
            end: endDate.toLocaleString("ru", {year: 'numeric', month: 'numeric', day: '2-digit', hour: "2-digit", minute: '2-digit', second: '2-digit'}),
            filterAccount: filterAccountUserType
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, data: SendData })
        };

        fetch(apiSource + apiGetInteractions, requestOptions)
        .then(response => response.json())
        .then(data => {
            setProjects(data.data);
            setDatesState(dates);
            setActions(data.actions);
            setNeedUpdate(false);
            setEnableContent(true);
        });
    }, [periodDate, needUpdate, filterAccount]);

    const projectsMap = projects.map( (item, i) =>
        <div className="item" style={{gridTemplateColumns: `repeat(${datesItems.length + 1}, 1fr)`}} key={item.id}>

            <div className="project"><PrintProject status={item.projectStatusID} name={item.ProjectName} /></div>
            { userData.userTypeID == '1' && <div className="manager"><img src={apiSource+item.AccountAvatar} /></div> }
            
            {datesState.map( (action, j) => 
                <div className={(new Date()).getDate() == action.getDate() && (new Date()).getMonth() == action.getMonth() && (new Date()).getFullYear() == action.getFullYear() && "date date-now" || (action.getDay() == 0 || action.getDay() == 6) && "date date-present" || "date"} key={j}  >
                    { actions.length !== 0 && 
                        <>
                            { actions[item.projectID] !== undefined &&
                                <>
                                    { actions[item.projectID][j+1] !== undefined && 
                                        <ActionSelect action={actions[item.projectID][j+1]} projectID={item.projectID} date={action} needUpdate={setNeedUpdate}/> 
                                        || <ActionSelect projectID={item.projectID} date={action} needUpdate={setNeedUpdate}/>
                                    } 
                                </> || <ActionSelect projectID={item.projectID} date={action} needUpdate={setNeedUpdate}/>
                            }
                        </> || <ActionSelect projectID={item.projectID} date={action} needUpdate={setNeedUpdate} />
                    }           
                </div>
            )}

        </div>
    );

    const resetFilter = () => {
        setPeriodDate( new Date() );
        setFilterAccount('');
    }

    

    return(
        <>
            <Topbar />
            <SideBar />
            <div className="work-area">
                <div className="top-area">
                    <div className="left">
                        <div className="header">Коммуникации</div>
                        <div className="peacker">
                            <DatePicker 
                                showMonthYearPicker 
                                dateFormat="MMM yyyy" 
                                selected={periodDate} 
                                onChange={(date) => setPeriodDate( date )} 
                            />
                        </div>

                        { userData.userTypeID == 1 && projectsMap.length > 0 &&
                            <div>
                                <UserSelect
                                    iconSize="20"
                                    getValue={(id) => setFilterAccount(id)}
                                    placeholder="аккаунт-менеджер"
                                    userTypeID="2"
                                    value={filterAccount}
                                    currentValue=""
                                />
                            </div>
                        }
                        
                        <div>
                            <button className="refresh-button" onClick={resetFilter}><RxReset /></button>
                        </div>
                    </div>  
                    <div className="right">            
                    </div>
                </div>     
                
                { projectsMap.length > 0 && enableContent && 
                    <div className="content-area"> 
                        <div className="interactions-table">
                            <div className="items">
                                <div className="item item-header" style={{gridTemplateColumns: `repeat(${datesItems.length + 1}, 1fr)`}}>
                                    <div className="project">Проект</div>
                                    { userData.userTypeID == '1' && <div className="manager"></div> }
                                    {datesItems}
                                </div>
                                {projectsMap}
                            </div>
                        </div>
                    </div>
                }

                { projectsMap.length == 0 && 
                    <div className="content-area">
                        <div className="period-no-project">
                            <div>
                                <div className="text">Тут пока ничего нет,</div>
                                <div className="text">но все можно исправить, со временем ...</div>
                                <div className="image">
                                    <Lottie animationData={Glass} loop={true} autoplay={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                }


                



            </div>
        </>
    )
    
}

export default Interactions