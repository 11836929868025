import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
    name: "Current User Login Storage",

    initialState: {
        token: '',
        userID: 0,
        userData: ''  
    },

    reducers: {
        loginUser( state, action ) {
            state.token = action.payload.token;
            state.userID = action.payload.userID;
            state.userData = action.payload.userData;
        },

        logoutUser( state ) {
            state.token = '';
            state.userID = 0;
        }
    }
});

export const { loginUser, logoutUser } = LoginSlice.actions;
export default LoginSlice.reducer;