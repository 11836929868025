import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";

import { IoMdArrowDropdown } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";



const UserArea = () => {
    const userData = useSelector(state => state.loginStore.userData);
    const [userMenuStatus, setUserMenuStatus] = useState(false);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const avatarSource = apiSource + userData.avatar;
    
    const [clientX, setClientX] = useState(0);
    const [clientY, setClientY] = useState(0);

    const openMenu = (e) => {
        setUserMenuStatus(!userMenuStatus);  
        setClientX(e.clientX + 20);
        setClientY(70);
    }

    const closeMenu = () => {
        setUserMenuStatus(false); 
    }

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        if (userMenuStatus === true) {
            setUserMenuStatus(false);
        }
    });


    return(
        <div ref={outsideAlerterRef}>
            <div className="user-area" onClick={(e) => openMenu(e)}>
                <div className="avatar">
                    <img src={avatarSource} alt="" />
                </div>
                <div className={userMenuStatus ? "name-icon name-icon-back" : "name-icon"}>
                    <div className="name">
                        {userData.name} {userData.lastname}
                    </div>
                    <div className={userMenuStatus ? "icon icon-translate" : "icon"}>
                        <IoMdArrowDropdown />
                    </div>
                </div>
            </div>

            <div className={userMenuStatus ? "user-area-menu" : "user-area-menu display-none"} style={{top: clientY, left: clientX}}>
                <div className="top">
                    <div className="title">
                        Дмитрий Чернецов
                    </div>
                    <div className="button" onClick={closeMenu}>
                        <RxCross1 />
                    </div>
                </div>
                <div className="content">
                    Пока ничего нет ...
                </div>
            </div>
        </div>
    )
}

export default UserArea