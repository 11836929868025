
import { MdOutlineCurrencyRuble } from "react-icons/md"

const PrintBudget = ( props ) => {
    return(
        <div className="element-print-budget">
            
            <div className="value">{props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
            <div className="icon"><MdOutlineCurrencyRuble size="16"/></div>
        </div>
    )
}

export default PrintBudget

