import Topbar from "../components/layout/TopBar"
import SideBar from "../components/layout/SideBar"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { ru } from 'date-fns/locale/ru';
import { AiOutlinePlus } from "react-icons/ai";
import { RxReset } from "react-icons/rx";
import { MdOutlineCurrencyRuble } from "react-icons/md"
import Lottie from "lottie-react";
import Glass from "../images/wired-gradient-567-french-fries-chips-hover-pinch.json";
import Modal from "../components/modals/Modal";
import ProjectInput from "../components/elements/ProjectInput";
import UserSelect from "../components/elements/UserSelect";
import PrintProject from "../components/elements/PrintProject";
import PrintUser from "../components/elements/PrintUser";
import IconInput from "../components/elements/IconInput";
import ButtonAddPayment from "../components/elements/ButtonAddPayment";
import PrintPlanOfPayment from "../components/elements/PrintPlanOfPayment"
import ButtonProjectMore from "../components/elements/ButtonProjectMore";
import ProjectStatusSelect from "../components/elements/ProjectStatusSelect";
import PrintProjectStatus from "../components/elements/PrintProjectStatus";
import ButtonCopyPeriod from "../components/elements/ButtonCopyPeriod";
import ButtonRemovePeriod from "../components/elements/ButtonRemovePeriod";








const Periods = () => {

    registerLocale('ru', ru);
    setDefaultLocale('ru');

    const [startDate, setStartDate] = useState(new Date());
    const [filterAccount, setFilterAccount] = useState('');
    const [filterSpp, setFilterSpp] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [addProjectModalStatus, setAddProjectModalStatus] = useState(false);
    const [addSelectedProjectID, setAddSelectedProjectID] = useState('');
    const [addSelectedAccountID, setaddSelectedAccountID] = useState('');
    const [addSelectedsppID, setaddSelectedsppID] = useState('');
    const [addBudget, setaddBudget] = useState('');
    const [addProjectStatus, setAddProjectsStatus] = useState('');
    const [projectErr, setProjectErr] = useState(false);
    const [projectExistsErr, setprojectExistsErr] = useState(false);
    const [accountErr, setAccountErr] = useState(false);
    const [sppErr, setsppErr] = useState(false);
    const [budgetErr, setbudgetErr] = useState(false);
    const [projectStatusErr, setProjectStatusErr] = useState(false);
    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiAddToPeriod = useSelector(state => state.configStore.apiAddToPeriod);
    const apiGetPeriod = useSelector(state => state.configStore.apiGetPeriod);
    const [periodItems, setPeriodItems] = useState([]);
    const [paymentItems, setPaymentItems] = useState([]);
    const [commentsOfPaymentsItems, setCommentsOfPaymentsItems] = useState([]);
    const [needUpdate, setNeedUpdate] = useState(false);
    const [enabledViewTable, setEnabledViewTable] = useState(false);
    const userData = useSelector(state => state.loginStore.userData);



    const AddProjectButtonClick = () => {
        const addData = {
            date: startDate,
            projectID: addSelectedProjectID,
            accountID: addSelectedAccountID,
            sppID: addSelectedsppID,
            budget: addBudget,
            status: addProjectStatus
        }

        if ( addSelectedProjectID !== ''  ) {
            setProjectErr(false);  
            if ( addSelectedAccountID !== '' ) {
                setAccountErr(false);
                if ( addSelectedsppID !== '') {
                    setsppErr(false);
                    if ( addBudget !== '' && addBudget.match(/^\d+$/) !== null) {
                        setbudgetErr(false);
                        if ( addProjectStatus !== '' ) {
                            setProjectStatusErr(false);
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ token: loginToken, data: addData })
                            };
                    
                            fetch(apiSource + apiAddToPeriod, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                console.log()
                                if ( !data.isExists ) {
                                    setAddProjectModalStatus(false);
                                    setNeedUpdate(true);
                                    setprojectExistsErr(false);
                                } else {
                                    setprojectExistsErr(true);
                                }
                            });
                        } else {
                            setProjectStatusErr(true);
                        }
                    } else {
                        setbudgetErr(true);
                    }
                } else {
                    setsppErr(true);
                }
            } else {
                setAccountErr(true);
            }            
        } else {
            setProjectErr(true);            
        }

        
       


        
    }

    const OpenModalAddProject = () => {
        setAddSelectedProjectID('');
        setaddSelectedAccountID('');
        setaddSelectedsppID('');
        setaddBudget('');
        setProjectErr(false);
        setAccountErr(false);
        setsppErr(false);
        setbudgetErr(false);
        setAddProjectModalStatus(true);
        setprojectExistsErr(false);
        setProjectStatusErr(false);
    }

    useEffect( () => {
        let filterAccountUserType;

        if (userData.userTypeID == '1' ) {
            filterAccountUserType = filterAccount;
        } else {
            filterAccountUserType = userData.id;
        }
        const queryData = {
            date: startDate,
            accountID: filterAccountUserType,
            sppID: filterSpp,
            statusID: filterStatus
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, data: queryData })
        };

        fetch(apiSource + apiGetPeriod, requestOptions)
        .then(response => response.json())
        .then(data => {
            setPeriodItems(data.data); 
            setPaymentItems(data.payments);
            setCommentsOfPaymentsItems(data.commentsOfPayment);
            setNeedUpdate(false);
            setEnabledViewTable(true);
        });
    }, [startDate, needUpdate, filterAccount, filterSpp, filterStatus]);
    
    const resetFilter = () => {
        setFilterAccount('');
        setFilterSpp('');
        setFilterStatus('');
        setStartDate( new Date() ); 
    }
   
    const periodItemsMap = periodItems.map( item => 
        <div className="period-table-item" key={item.id}>
            <div><PrintProject status={item.status} name={item.name} /></div>
            <div>
                <PrintProjectStatus 
                    data={{
                        id: item.ProjectStatusID,
                        name: item.ProjectStatusName,
                        text: item.ProjectStatusText,
                        color: item.ProjectStatusColor
                    }} 
                />
            </div>
            <div>
                <PrintPlanOfPayment 
                    setNeedUpdate={setNeedUpdate} 
                    budget={item.budget} 
                    payments={paymentItems[item.id]} 
                    period={startDate} 
                    periodID={item.id} 
                    projectStatusID={item.ProjectStatusID} 
                    commentsOfPayment={commentsOfPaymentsItems[item.id]} 
                />
            </div>
            { userData.userTypeID == '1' && <div><PrintUser name={item.accountName} lastname={item.accountLastName} avatar={apiSource + item.accountAvatar}/></div>}
            <div><PrintUser name={item.sppName} lastname={item.sppLastName} avatar={apiSource + item.sppAvatar}/></div>
            <div className="table-buttons">
                <div>
                    <ButtonAddPayment 
                        periodID={item.id} 
                        periodMonth={startDate}
                        projectID={item.projectID}
                        projectStatus={item.status}
                        projectName={item.name}
                        budget={item.budget}
                        setNeedUpdate={setNeedUpdate}
                    />
                </div>
                <div>
                    <ButtonProjectMore 
                        data={item}
                        needUpdate={setNeedUpdate}
                    />
                </div>
                { userData.userTypeID == '1' && ( item.status == '1' || item.status == '4' )  &&
                    <div>
                        <ButtonRemovePeriod needUpdate={setNeedUpdate} data={item} />
                    </div>  
                }
            </div>
            
        </div>
    );



 

    return(
        <>
            <Topbar />
            <SideBar />
            <div className="work-area">
                <div className="top-area">
                    <div className="left">
                        <div className="header">Дебиторка</div>
                        <div className="peacker">
                            <DatePicker 
                                showMonthYearPicker 
                                dateFormat="MMM yyyy" 
                                selected={startDate} 
                                onChange={(date) => setStartDate( date )} 
                            />
                        </div>

                        { periodItems.length > 0 && 
                            <>
                                <div>
                                    <ProjectStatusSelect 
                                        iconSize="20"
                                        placeholder="статус"
                                        getValue={(id) => setFilterStatus(id)}
                                        value={filterStatus}
                                        currentValue=""
                                    />
                                </div>

                                { userData.userTypeID == '1' && 
                                    <div>
                                        <UserSelect
                                            iconSize="20"
                                            getValue={(id) => setFilterAccount(id)}
                                            placeholder="аккаунт-менеджер"
                                            userTypeID="2"
                                            value={filterAccount}
                                            currentValue=""
                                        />
                                    </div>
                                }
                                
                                <div>
                                    <UserSelect
                                        iconSize="20"
                                        getValue={(id) => setFilterSpp(id)}
                                        placeholder="специалист"
                                        userTypeID="3"
                                        value={filterSpp}
                                        currentValue=""
                                    />
                                </div>
                            </>
                        }


                        <div>
                            <button className="refresh-button" onClick={resetFilter}><RxReset /></button>
                        </div>
                    </div>  
                    <div className="right">

                    { userData.userTypeID == '1' &&
                        <>
                            { periodItems.length > 0 && <ButtonCopyPeriod  currentPeriod={startDate} /> }
                            
                            <button className="btn-transparent btn-inline" type="button" onClick={OpenModalAddProject}>
                                <AiOutlinePlus />
                            </button>
                        </>   
                    }
                        
                    </div>
                </div>

                <div className="content-area">
                    {periodItemsMap.length > 0 && 
                        <div className="period-table">
                            <div className="period-table-item period-table-item-header">
                                <div>Проект</div>
                                <div>Статус</div>
                                <div>План оплаты</div>
                                { userData.userTypeID == '1' && <div>Менеджер</div> }
                                <div>Специалист</div>
                            </div>
                            {periodItemsMap}
                        </div>
                    }
                    {periodItemsMap.length == 0 &&
                        <div className={enabledViewTable ? "period-no-project" : "display-none"}>
                            <div>
                                <div className="text">Тут пока ничего нет,</div>
                                <div className="text">но все можно исправить, со временем ...</div>
                                <div className="image">
                                    <Lottie animationData={Glass} loop={true} autoplay={true} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                
            </div>

            {addProjectModalStatus && 
                <Modal 
                    status={setAddProjectModalStatus}
                    content={
                        <div className="add-project-form">
                            <div className="form-item">
                                <label>Проект:</label>
                                <ProjectInput
                                    iconSize="20"
                                    getValue={(id) => setAddSelectedProjectID(id)}
                                    placeholder="начните писать домен..."
                                    statuses={['1', '2', '3', '4']}
                                />
                                <div className={projectErr ? "element-error" : "element-error display-none"}>Необходимо выбрать проект</div>    
                                <div className={projectExistsErr ? "element-error" : "element-error display-none"}>Такой проект уже есть</div>                    
                            </div>

                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>Менеджер:</label>
                                <UserSelect
                                    iconSize="20"
                                    getValue={(id) => setaddSelectedAccountID(id)}
                                    placeholder="Аккаунт-менеджер"
                                    userTypeID="2"
                                    currentValue=""
                                />
                                <div className={accountErr ? "element-error" : "element-error display-none"}>Необходимо выбрать менеджера</div>
                            </div>

                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>Специалист по продвижению:</label>
                                <UserSelect
                                    iconSize="20"
                                    getValue={(id) => setaddSelectedsppID(id)}
                                    placeholder="Специалист"
                                    userTypeID="3"
                                    currentValue=""
                                />
                                <div className={sppErr ? "element-error" : "element-error display-none"}>Необходимо выбрать специалиста</div>
                            </div>

                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>План оплаты:</label>
                                <IconInput
                                    iconSize="20"                                    
                                    placeholder="план оплаты"
                                    icon={<MdOutlineCurrencyRuble size="20"/>}
                                    onChange={(e) => setaddBudget(e.target.value)}
                                    currentValue=""
                                    id="plan-of-payment"

                                />
                                <div className={budgetErr ? "element-error" : "element-error display-none"}>Необходимо указать бюджет</div>
                            </div>

                            <div className="form-item">
                            <label style={{marginTop: '30px'}}>Статус:</label>
                                <ProjectStatusSelect 
                                    iconSize="20"
                                    placeholder="Статус"
                                    getValue={(id) => setAddProjectsStatus(id)}
                                    currentValue=""
                                />
                                <div className={projectStatusErr ? "element-error" : "element-error display-none"}>Необходимо указать статус проекта</div>
                            </div>
                            
                        </div>
                    }
                    title="Добавить проект"
                    buttons={[
                        { 
                            title: "Добавить",  
                            type: "btn-green", 
                            icon: <AiOutlinePlus />, 
                            on: AddProjectButtonClick, 
                            id: 0 
                        }
                    ]}
                />
            }
            
        </>
    )
    
}

export default Periods