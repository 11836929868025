import { configureStore } from "@reduxjs/toolkit";

import LoginReducer from './auth';
import ConfigStore from './config';

export default configureStore({
    reducer: { 
        loginStore: LoginReducer, 
        configStore: ConfigStore
    }
});