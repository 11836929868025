import { MdAddCard } from "react-icons/md";
import { MdOutlineCurrencyRuble } from "react-icons/md"
import Modal from "../modals/Modal";
import { useState } from "react";
import PrintProject from "./PrintProject";
import PrintBudget from "./PrintBudget";
import IconInput from "./IconInput";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

const ButtonAddPayment = (props) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiAddToPayments = useSelector(state => state.configStore.apiAddToPayments);

    const [modalStatus, setModalStatus] = useState( false );
    const [summa, setSumma] = useState('');
    const [budgetErr, setbudgetErr] = useState(false);
    const [dateErr, setDateErr] = useState(false);
    const [dateOfPayment, setdateOfPayment] = useState( new Date() );

    const openModal = () => {
        setModalStatus(true);
        setSumma('');
        setbudgetErr(false);
        setDateErr(false);
        setdateOfPayment( new Date());
    }

    const modalButtonClick = () => {

        if ( dateOfPayment !== '' ) {
            setDateErr(false);
            if ( summa !== '0' && summa !== '' && summa.match(/^\d+$/) !== null ) {
                setbudgetErr(false);
                
                const AddData = {
                    periodID: props.periodID,
                    projectID: props.projectID,
                    datePayment: dateOfPayment,
                    budget: summa
                }
    
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token: loginToken, data: AddData })
                };
        
                fetch(apiSource + apiAddToPayments, requestOptions)
                .then(response => response.json())
                .then(data => {
                    props.setNeedUpdate(true);
                    setModalStatus(false);
                });
                
            } else {
                setbudgetErr(true);
            }
        } else {
            setDateErr(true);
        }
        
    }

    return(
        <>
            <div className="element-button-add-payment">
                <button onClick={openModal}><MdAddCard /></button>
            </div>

            {modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <div className="add-project-form">
                            <div className="form-item">
                                <label>Проект:</label>
                                <div className="label-item label-passive">
                                    <PrintProject status={props.projectStatus} name={props.projectName} />
                                </div>
                            </div>
                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>Оплата за:</label>
                                <div className="label-item label-passive">
                                    {props.periodMonth.toLocaleString("ru", {month: 'long', year: 'numeric'})}
                                </div>
                            </div>
                            
                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>План оплаты:</label>
                                <div className="label-item label-passive">
                                    <PrintBudget value={props.budget} />
                                </div>
                            </div>
                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>Дата внесения платежа:</label>
                                <DatePicker 
                                    dateFormat="dd MMM yyyy" 
                                    selected={dateOfPayment} 
                                    onChange={(date) => setdateOfPayment( date )} 
                                />
                                <div className={dateErr ? "element-error" : "element-error display-none"}>Необходимо указать дату платежа</div>
                            </div>
                            <div className="form-item">
                                <label style={{marginTop: '30px'}}>Сумма поступлений:</label>
                                <IconInput
                                    iconSize="20"                                    
                                    placeholder="сумма"
                                    icon={<MdOutlineCurrencyRuble size="20"/>}
                                    onChange={e => setSumma(e.target.value)}
                                    currentValue=""
                                    id="payment-input-budget"
                                />
                                <div className={budgetErr ? "element-error" : "element-error display-none"}>Необходимо указать сумму</div>
                            </div>
                        </div>
                    }
                    title="Внести платеж"
                    buttons={[
                        { 
                            title: "Внести платеж",  
                            type: "btn-green", 
                            icon: <MdAddCard />, 
                            on: modalButtonClick, 
                            id: 0 
                        }
                    ]}
                />
            }
        </>
    )

}

export default ButtonAddPayment