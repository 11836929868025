import { BrowserRouter, Routes, Route } from "react-router-dom"
import Main from "./pages/Main"
import Login from "./pages/Login"
import Periods from "./pages/Periods"
import Projects from "./pages/Projects"
import Settings from "./pages/Settings"
import Interactions from "./pages/Interactions"
import { Provider } from "react-redux"

import './styles/styles.css'
import './styles/elements.css'

import store from './store/index';
import AccessControl from "./components/AccessControl"

const App = () => {
  
  return(
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/login"         element={<Login />}                                 />
            <Route path="/"              element={<AccessControl page={<Main          />}/>} />
            <Route path="/interactions"  element={<AccessControl page={<Interactions  />}/>} />
            <Route path="/periods"       element={<AccessControl page={<Periods       />}/>} />
            <Route path="/projects"      element={<AccessControl page={<Projects      />}/>} />
            <Route path="/settings"      element={<AccessControl page={<Settings      />}/>} />
          </Routes>
        </BrowserRouter>
      </Provider>
      
    </>
  )
}

export default App
