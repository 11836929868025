import { RxCross1 } from "react-icons/rx";
import { useEffect, useRef } from "react";

const Modal = ( props ) => {

    document.body.style.overflow = 'hidden';

    const onClose = () => {
        props.status(false)
        document.body.style.overflow = 'initial';
    }

    function useOutsideAlerter(onOutsideClick) {
        const ref = useRef();
        useEffect(() => {
            function handleClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onOutsideClick();
                }
            }
            document.addEventListener('mousedown', handleClick);
            return () => {
                document.removeEventListener('mousedown', handleClick);
            };
        }, [onOutsideClick]);
        return ref;
    }

    const outsideAlerterRef = useOutsideAlerter(() => {
        onClose();
    });


    const buttonsMap = props.buttons.map( item => 
        <button 
            type="button" 
            className={item.type}
            onClick={item.on}
            key={item.id}
        >
            {item.icon}{item.title}
        </button>
    );

    
    return(
        <div className="element-modal-wrapper">
            <div className="modal-container" ref={outsideAlerterRef}>
                <div className="header">
                    <div className="title">{props.title}</div>
                    <div className="close"><button type="button"><RxCross1 onClick={onClose}/></button></div>
                </div>
                <div className="content">
                    {props.content}
                </div>
                <div className="buttons">
                    {buttonsMap}
                    <button className="btn-gray" type="button" onClick={onClose}><RxCross1 />Закрыть</button>
                </div>
            </div>
        </div>
    )
}

export default Modal