import { IoIosMore } from "react-icons/io";
import { IoCheckmarkSharp } from "react-icons/io5";
import Modal from "../modals/Modal";
import { useState, useEffect } from "react";
import ProjectStatusSelect from "./ProjectStatusSelect";
import UserSelect from "./UserSelect";
import { MdOutlineCurrencyRuble } from "react-icons/md"
import IconInput from "./IconInput";
import PrintProject from "./PrintProject";
import { useSelector } from "react-redux";
import PrintDateMonth from "./PrintDateMonth";
import PrintBudget from "./PrintBudget";
import PrintDateTime from "./PrintDateTime";
import ButtonRomovePayment from "./ButtonRomovePayment";



const ButtonProjectMore = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiGetPayments = useSelector(state => state.configStore.apiGetPayments);
    const apiUpdatePeriod = useSelector(state => state.configStore.apiUpdatePeriod);
    const userData = useSelector(state => state.loginStore.userData);
 
    const [paymentsData, setPaymentsData] = useState([]);
    const [needPaymentUpdate, setNeedPaymentUpdate] = useState(false);


    const [modalStatus, setModalStatus] = useState(false);
    const [projectStatus, setProjectStatus] = useState(props.data.ProjectStatusID);
    const [accountUserID, setAccountUserID] = useState(props.data.accountUserID);
    const [sppUserID, setSppUserID] = useState(props.data.sppUserID);
    const [budget, setBudget] = useState(props.data.budget);



    const OpenModal = () => {
        setModalStatus(true);
    }

    const ApplyButtonClick = () => {
        const updateData = {
            id: props.data.id,
            projectID: props.data.projectID,
            accountUserID: accountUserID,
            sppUserID: sppUserID,
            budget: budget,
            projectStatusID: projectStatus
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, data: updateData })
        };

        fetch(apiSource + apiUpdatePeriod, requestOptions)
        .then(response => response.json())
        .then(data => {
            setModalStatus(false);
        });
    }

    useEffect(()=>{
        props.needUpdate(true);
    }, [modalStatus]);

    useEffect( () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, projectID: props.data.projectID })
        };

        fetch(apiSource + apiGetPayments, requestOptions)
        .then(response => response.json())
        .then(data => {
            setPaymentsData(data.payments);
            setNeedPaymentUpdate(false);
        });
    }, [modalStatus, needPaymentUpdate]);

    const paymentsItems = paymentsData.map( item => 
        <div className="item" key={item.id}>
            <PrintDateMonth month={item.Month} />
            <PrintBudget value={item.budget} />
            <PrintDateTime value={item.payDateTime} />
            <ButtonRomovePayment data={item} needUpdate={setNeedPaymentUpdate}/>
        </div>
    );

    return(
        <>
            <div className="element-project-more-button">
                <button onClick={OpenModal}><IoIosMore /></button>
            </div>

            {modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div className="add-project-form">
                                <div className="form-item-grid">
                                    <div className="form-item">
                                        <label>Проект:</label>
                                        <div className="label-item label-passive">
                                            <PrintProject status={props.data.ProjectStatusID} name={props.data.name} />
                                        </div>
                                    </div>
                            
                                </div>
                                <div className="form-item-grid">
                                    { userData.userTypeID == '1' && 
                                        <>
                                            <div>
                                                <label>Изменить менеджера:</label>
                                                <UserSelect
                                                    iconSize="20"
                                                    getValue={(id) => setAccountUserID(id)}
                                                    placeholder="Аккаунт-менеджер"
                                                    userTypeID="2"
                                                    currentValue={props.data.accountName + ' ' + props.data.accountLastName}
                                                />
                                            </div>
                                            <div>
                                                <label>Изменить специалиста:</label>
                                                <UserSelect
                                                    iconSize="20"
                                                    getValue={(id) => setSppUserID(id)}
                                                    placeholder="Специалист"
                                                    userTypeID="3"
                                                    currentValue={props.data.sppName + ' ' + props.data.sppLastName}
                                                />
                                            </div>
                                        </>
                                    }
                                    
                                    <div>
                                        <label>Изменить статус проекта:</label>
                                        <ProjectStatusSelect 
                                            iconSize="20"
                                            placeholder="Статус"
                                            getValue={(id) => setProjectStatus(id)}
                                            currentValue={props.data.ProjectStatusText}
                                        />
                                    </div>
                                    <div>
                                        <label>Изменить бюджет:</label>
                                        <IconInput
                                            iconSize="20"                                    
                                            placeholder="план оплаты"
                                            icon={<MdOutlineCurrencyRuble size="20"/>}
                                            currentValue={props.data.budget}
                                            onChange={(e) => setBudget(e.target.value)}
                                            id="inputStartBudget"
                                           
                                        />
                                    </div>
                                </div>

                               
                                <div className="payments-history">
                                    <label>История платежей:</label>
                                    { paymentsItems.length > 0 &&
                                        <div className="items">
                                            <div className="item item-header">
                                                <div>Период</div>
                                                <div>Сумма</div>
                                                <div>Дата оплаты</div>
                                            </div>
                                            {paymentsItems}
                                        </div>
                                    }

                                    { paymentsItems.length == 0 &&
                                        <div className="nothing">Ничего нет, пока что...</div>
                                    }
                                </div>
                                
                                
                          
                                    
                                
                            </div>
                        </>
                    }
                    title="Подробная информация о проекте"
                    buttons={[
                        { 
                            title: "Сохранить",  
                            type: "btn-green", 
                            icon: <IoCheckmarkSharp />, 
                            on: ApplyButtonClick, 
                            id: 0 
                        }
                    ]}
                />
            }
        </>
    )
}

export default ButtonProjectMore