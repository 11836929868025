import { GoHourglass } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { AiOutlineQuestion } from "react-icons/ai";
import { FaPause } from "react-icons/fa6";
import { FaStop } from "react-icons/fa6";



const PrintProject = ( props ) => {

    let icon;


    switch(props.status) {
        case '1':
            icon = <GoHourglass size="16" />
            break;
        
        case '2':
            icon = <FaPlay size="12" color="var(--button-bg-green)" />
            break;
        
        case '3':
            icon = <FaPause size="12" color="var(--text-color-orange)" />
            break;

        case '4':
            icon = <FaStop size="12" color="var(--text-color-red)" />
            break;
        
        default: 
            icon = '';
            break;
    
    }

    return(
        <div className="element-project">
            <div className="icon">{icon}</div>
            <div className="domain">{props.name}</div>
        </div>
    )
}

export default PrintProject