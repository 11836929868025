const PrintUser = ( props ) => {

    return(
        <div className="element-print-user">
            <div className="avatar">
                <img src={props.avatar} />
            </div>
            <div className="name">
                {props.name} {props.lastname}
            </div>
        </div>
    )
}

export default PrintUser