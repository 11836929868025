import { RxCalendar } from "react-icons/rx";

const PrintDateMonth = ( props ) => {

    const PeriodMonth = new Date(props.month);


    return(
        <div className="element-print-month">
            <div className="icon"><RxCalendar /></div>
            <div className="value">
                {PeriodMonth.toLocaleString("ru", {month: 'short', year: 'numeric'})}
            </div>
        </div>
    )
}

export default PrintDateMonth