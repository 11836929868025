import { useState } from "react"
import { useDispatch } from "react-redux";
import { loginUser } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { BsBoxArrowRight } from "react-icons/bs";
import { IoAdd } from "react-icons/io5";
import { TiWarningOutline } from "react-icons/ti";

import IconInput from "../components/elements/IconInput";
import { PiUserCircleLight } from "react-icons/pi";
import { SiMonkeytie } from "react-icons/si";


const Login = () => {
    const dispatch = useDispatch();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loginOk, setLoginOk] = useState(true);
    const [noAuthorize, setNoAuthorize] = useState(true);
    
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiAuthorizeUrl = useSelector(state => state.configStore.apiAuthorizeUrl);

    const navigate = useNavigate();

    const Submit = ( event ) => {
        event.preventDefault();

        if (login === '') { setLoginOk(false); } else { setLoginOk(true); }

        if ( login !== '' ) {            

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ login: login, password: password })
            };
            
            fetch(apiSource + apiAuthorizeUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.isAuthorize === true) {
                        dispatch(loginUser({token: data.token, userID: data.userData.id, userData: data.userData}));
                        navigate('/');                        
                    } else {
                        setNoAuthorize(false);
                    }
                });
        }

        
    }

    return(
        <div className="form-wrapper">
            <div>
                <div className="form-logo">CRM</div>
                <div className="form-window">
                    <form className="form-login" onSubmit={(e) => Submit(e)}>
                        <div className="form-item">
                            <label htmlFor="login">Имя пользователя:</label>
                            <IconInput 
                                id="login"
                                name="login"
                                type="text"
                                onChange={(e) => setLogin(e.target.value)}
                                iconSize="20"
                                icon={<PiUserCircleLight size="20" />}
                                currentValue=""
                            />
                            <div className={loginOk ? "form-error display-none" : "form-error"}><div><TiWarningOutline />Поле не может быть пустым</div></div>
                            <div className={noAuthorize ? "form-error display-none" : "form-error"}><div><TiWarningOutline />Неверный логин или пароль</div></div>
                        </div>

                        <div className="form-item">
                            <label htmlFor="password">Пароль:</label>
                            <IconInput 
                                id="password"
                                name="password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                iconSize="20"
                                icon={<SiMonkeytie size="20" />}
                                currentValue=""
                            />
                        </div>

                        <div className="form-item form-buttons">
                            <div className="item">
                                <button className="btn-green" type="submit"><BsBoxArrowRight />Войти</button>
                            </div>
                            <div className="item">
                                <button className="btn-blue" type="button"><IoAdd />Регистрация</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="form-bottom">Все права защищены. Разработано <a href="https://t.me/theextra87" target="_blank" rel="noreferrer">@theextra</a></div>
            </div>
        </div>
    )
}

export default Login