import UserArea from "./TopBarUserArea";
import TopBarDebitorka from "./TopBarDebitorka";

import { useDispatch, useSelector } from "react-redux";
import { setSideBarStatus } from "../../store/config";

import { LiaToggleOffSolid, LiaToggleOnSolid } from "react-icons/lia";
import { CiSettings, CiSearch } from "react-icons/ci";





const Topbar = () => {

    const dispatch = useDispatch();
    const sideBarStatus = useSelector(state => state.configStore.sideBarStatus)
    

    const OpenSideBar = () => {
        if (sideBarStatus === false) {
            dispatch(setSideBarStatus({sideBarStatus: true}));
        }
    }

    return(
        <>
            <div className="top-bar">
                <div className="left">
                    <div className="button">
                        <button className={sideBarStatus ? "display-none" : ""} type="button" onClick={OpenSideBar}><LiaToggleOffSolid size="27"/></button>
                        <button className={sideBarStatus ? "" : "display-none"} type="button"><LiaToggleOnSolid size="27"/></button>
                    </div>
                    <UserArea />
                    {/* <TopBarDebitorka text="окт. 2024" current="400000" plan="876345"/>
                    <TopBarDebitorka text="сен. 2024" current="800000" plan="876345"/> */}
                </div>
                <div className="right">
                    {/* <div className="search">
                        <div className="icon"><CiSearch size="20" /></div>
                        <div className="input"><input placeholder="Поиск..."/></div>
                    </div>
                    <div>
                        <button type="button"><CiSettings size="25"/></button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Topbar