import { MdOutlineCurrencyRuble } from "react-icons/md"
import { FaCheck } from "react-icons/fa";
import { GoHourglass } from "react-icons/go";
import Modal from "../modals/Modal";
import { useState, useEffect } from "react";
import { FaRegCommentDots } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { GoCheck } from "react-icons/go";
import PrintDateTime from "./PrintDateTime";
import PrintDateTimeNoHour from "./PrintDateTimeNoHour";
import { RxCross1 } from "react-icons/rx";


const PrintPlanOfPayment = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const userData = useSelector(state => state.loginStore.userData);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiAddPaymentComment = useSelector(state => state.configStore.apiAddPaymentComment);
    const apiRemovePaymentComment = useSelector(state => state.configStore.apiRemovePaymentComment);

    let percent = 0;
    let percentNoNorm = 0;
    let paymentsString = '0';
    let budgetString = '0';
    let color = 'rgba(255, 255, 255, .5)';

    if ( props.budget !== null ) {
        budgetString = props.budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    if ( props.payments !== null ) {
        paymentsString = props.payments.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } 

    if ( props.budget !== null && props.payments !== null ) {
        percent = Math.round(( props.payments / props.budget ) * 100);
        percentNoNorm = Math.round(( props.payments / props.budget ) * 100);
        if ( percent >= 75 ) {
            color = '#8ACC9D';
        }
        if ( percent > 100 ) {
            percent = 100;
            color = 'var(--text-color-red)';
        }
    }

    const nowDate = new Date();
    const nowDateYear = nowDate.getFullYear();
    const nowDateMonth = nowDate.getMonth() + 1;
    const currentPeriodMonth = props.period.getMonth() + 1;
    const currentPeriodYear = props.period.getFullYear();

    let ClockColor = 'rgba(255, 255, 255, .5)';
    let iconAnimate = '';
    let glassIcon = <GoHourglass color={ClockColor} size="18"/>
    let dateEnabled = false;

    if ( nowDateYear == currentPeriodYear && nowDateMonth == currentPeriodMonth ) {
        ClockColor = 'var(--text-color-red)';
        iconAnimate = 'icon-animate';
        glassIcon = <GoHourglass color={ClockColor} size="18"/>
        dateEnabled = true;
    }

    const [planModalStatus, setPlanModalStatus] = useState(false);
    const [datePlanPay, setDatePlanPay] = useState( new Date() );
    const [datePlanEnabled, setDatePlanEnabled] = useState( true );
    const [textComment, setTextComment] = useState('');
    const [commentError, setCommentError] = useState(false);

  

    const SendComment = () => {

        if ( textComment !== '' ) {
            setCommentError(false);
            const sendData = {
                periodID: props.periodID,
                planDate: datePlanPay,
                planDateEnabled: datePlanEnabled,
                text: textComment,
                userID: userData.id
            };
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: loginToken, data: sendData })
            };
    
            fetch(apiSource + apiAddPaymentComment, requestOptions)
            .then(response => response.json())
            .then(data => {
                props.setNeedUpdate(true);
                setTextComment('');
                setDatePlanPay( new Date() );
            });
        } else {
            setCommentError(true);
        }
        

    }

    const onDeleteComment = (id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, commentID: id })
        };

        fetch(apiSource + apiRemovePaymentComment, requestOptions)
        .then(response => response.json())
        .then(data => {
            props.setNeedUpdate(true);
        });
    }

    let lastCommentDate;
    if ( props.commentsOfPayment.length > 0 ) {
        lastCommentDate = new Date(props.commentsOfPayment[0].planDate);
    } else {
        lastCommentDate = '';
    }

    const commentsItems =  props.commentsOfPayment.map( item =>
        <div className="item" key={item.id}>
            <div className="avatar"><img src={apiSource+item.UserAvatar} /></div>
            <div className="cont">
                <div className="text">
                    <div className="date-pub date-pub-flex">
                        <div><PrintDateTime value={item.datetime} /></div>
                        { item.UserID == userData.id &&
                            <div>
                                <button stype="button" onClick={() => onDeleteComment(item.id)}><RxCross1 /></button>
                            </div>
                        }
                    </div>
                    <div className="txt">{item.text}</div>
                </div>
                <div className="date">
                    { item.planDate !== '0000-00-00 00:00:00' &&
                        <PrintDateTimeNoHour value={item.planDate} />
                    }
                </div>
            </div>
        </div>
    );

    useEffect(()=>{
        if ( datePlanPay !== null ) {
            setDatePlanEnabled(true);
        } else {
            setDatePlanEnabled(false);
        }

        if (props.projectStatusID === '4') {
            setDatePlanEnabled(false);
        }
    }, [datePlanPay, props.projectStatusID]);

    


    return(
        <>
            { props.projectStatusID !== '4' &&
                <>
                    { percentNoNorm == 100 && 
                        <div className="element-plan-of-payment" onClick={() => setPlanModalStatus(true)}>
                            <div className="icon"><FaCheck color={'rgb(138, 204, 157)'} size="16"/></div>
                            <div className="value">
                                <div className="val-flex">
                                    <div className="val-icon"><MdOutlineCurrencyRuble size="16" color={'rgba(255, 255, 255, .5)'}/></div>
                                    <div className="val-text">{budgetString}</div>
                                </div>
                            </div>
                        </div>
                    }

                    { percentNoNorm !== 100 && 
                        <div className="element-plan-of-payment" onClick={() => setPlanModalStatus(true)}>
                            <div className={"icon " + iconAnimate}>{glassIcon}</div>
                            <div className="value">
                                <div className="val-flex" style={{justifyContent: 'space-between'}}>
                                    <div className="val-flex">
                                        <div className="val-icon"><MdOutlineCurrencyRuble size="16" color={'rgba(255, 255, 255, .5)'}/></div>
                                        <div className="val-text">{paymentsString} <span>из {budgetString}</span></div>
                                    </div>
                                    { dateEnabled == true && 
                                        <>
                                            { props.commentsOfPayment.length > 0 &&
                                                <div className="val-flex">
                                                    <div className="val-plan-payment">
                                                        {lastCommentDate.toLocaleString("ru", {day: '2-digit', month: 'short'})}
                                                    </div>
                                                </div>
                                            }
                                            { props.commentsOfPayment.length == 0 &&
                                                <div className="val-flex">
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div><IoIosWarning color="var(--text-color-red)" size="14" /></div>
                                                        <div style={{fontSize: '12px', color: 'var(--text-color-red)', marginLeft: '5px'}}>когда?</div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="progress-container">
                                    <div className="progress-value" style={{width: `${percent}%`, backgroundColor: `${color}`}}></div>
                                </div>
                            </div>
                        </div> 
                    }
                </>
            }

            { props.projectStatusID == '4' &&
                <div className="element-plan-of-payment" onClick={() => setPlanModalStatus(true)}>
                    <div className="icon"><FaRegCommentDots color={'var(--font-color)'} size="16"/></div>
                    <div className="value" style={{color: 'rgba(255, 255, 255, .5)'}}>
                        { props.commentsOfPayment.length == 0 && 
                            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                <div><IoIosWarning color="var(--text-color-red)" size="18" /></div>
                                <div style={{color: 'var(--text-color-red)', marginLeft: '5px', fontSize: '14px'}}>нужны комментарии</div>
                            </div>
                        }
                        { props.commentsOfPayment.length > 0 && 
                            <div>{props.commentsOfPayment.length} коммент.</div>
                        }
                    </div>
                </div>
            }
            
            { planModalStatus && 
                <Modal 
                    status={setPlanModalStatus}
                    content={
                        <>
                            <div className="element-add-pay-comment">
                                <div className="form-item">
                                    <div className="element-comment-input">
                                        <div className="avatar">
                                            <img src={apiSource + userData.avatar} />
                                        </div>
                                        <div className="textarea">
                                            <textarea 
                                                placeholder="укажите комментарий..." 
                                                onChange={ e => setTextComment(e.target.value)}
                                                value={textComment}
                                            ></textarea>

                                            { commentError == true && <div className="element-error">Необходимо указать комментарий</div> }
                                            
                                            { datePlanEnabled == true &&
                                                <div className="date-picker-area">
                                                    <label>Когда обещает оплатить:</label>
                                                    <DatePicker 
                                                        dateFormat="dd MMM yyyy" 
                                                        onChange={ (date) => setDatePlanPay(date) }
                                                        selected={datePlanPay}
                                                    />
                                                </div>
                                            }

                                            <div className="button">
                                                <button type="button" className="btn-green" onClick={SendComment}><GoCheck />Отправить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            { props.commentsOfPayment.length > 0 && 
                                <div className="element-pay-comments">
                                    <div className="items">
                                        {commentsItems}
                                    </div>
                                </div>
                            }
                            
                        </>
                    }
                    title="План оплаты"
                    buttons={[]}
                />
            }
            

                    
        </>
        
    )
}

export default PrintPlanOfPayment