import { RxCalendar } from "react-icons/rx";


const PrintDateTimeNoHour = ( props ) => {

    const DataValue = new Date(props.value);


    return(
        <div className="element-print-month">
            <div className="icon"><RxCalendar /></div>
            <div className="value">
                {DataValue.toLocaleString("ru", {day: '2-digit', month: 'short', year: 'numeric'})}
            </div>
        </div>
    )
}

export default PrintDateTimeNoHour