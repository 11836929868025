import { IoTrashOutline } from "react-icons/io5";
import Modal from "../modals/Modal";
import { useState } from "react";
import PrintDateMonth from "./PrintDateMonth";
import PrintBudget from "./PrintBudget";
import PrintDateTime from "./PrintDateTime";
import { useSelector } from "react-redux";


const ButtonRomovePayment = ( props ) => {

    const loginToken = useSelector(state => state.loginStore.token);
    const apiSource = useSelector(state => state.configStore.apiSource);
    const apiDelPayment = useSelector(state => state.configStore.apiDelPayment);

    const [modalStatus, setModalStatus] = useState(false);

    const RemoveButtonClick = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, paymentID: props.data.id })
        };

        fetch(apiSource + apiDelPayment, requestOptions)
        .then(response => response.json())
        .then(data => {
            props.needUpdate(true);
            setModalStatus(false);
        });
       
    }

    const OpenModal = () => {
        setModalStatus(true);
    }

    return(
        <>
            <div className="element-remove-payment">
                <button type="button" onClick={OpenModal}>
                    <IoTrashOutline color='var(--text-color-red)'/>
                </button>
            </div>

            {modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div style={{marginBottom: '20px'}}>Вы уверены, что хотите удалить данные платеж?</div>
                            <div className="payments-history">
                                <div className="items">
                                    <div className="item" style={{gridTemplateColumns: 'repeat(3, 1fr)'}}>
                                        <PrintDateMonth month={props.data.Month} />
                                        <PrintBudget value={props.data.budget} />
                                        <PrintDateTime value={props.data.payDateTime} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    title="Вы уверены?"
                    buttons={[
                        { 
                            title: "Удалить",  
                            type: "btn-red", 
                            icon: <IoTrashOutline />, 
                            on: RemoveButtonClick, 
                            id: 0
                        }
                    ]}
                />
            }


        </>
        
    )
}

export default ButtonRomovePayment