import { AiOutlinePlus }            from "react-icons/ai";
import Modal                        from "../../modals/Modal";
import { useEffect, useState }      from "react";
import IconInput                    from "../../elements/IconInput";
import { BsFolder2Open }            from "react-icons/bs";
import { useSelector }              from "react-redux";
import DatePicker                   from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
import { registerLocale, setDefaultLocale } from  "react-datepicker";

const ButtonAddProject = ( props ) => {

    registerLocale('ru', ru);
    setDefaultLocale('ru');

    const loginToken                                    = useSelector(state => state.loginStore.token);
    const apiSource                                     = useSelector(state => state.configStore.apiSource);
    const apiAddProject                                 = useSelector(state => state.configStore.apiAddProject);
    const [modalStatus, setModalStatus]                 = useState(false);
    const [projectName, setProjectName]                 = useState('');
    const [projectNameError, setProjectNameError]       = useState(false);
    const [projectExistsError, setprojectExistsError]   = useState(false);
    const [startDate, setStartDate]                     = useState( new Date());


    const ButtonAdd = () => {
        if ( projectName !== '' ) {
            setProjectNameError(false);

            const addData = {
                name: projectName,
                startDate: startDate
            };

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: loginToken, data: addData})
            };
    
            fetch(apiSource + apiAddProject, requestOptions)
            .then(response => response.json())
            .then(data => {
                if ( data.isExists === false) {
                    setprojectExistsError(false);
                    props.needToUpdate(true);
                    setModalStatus(false);
                } else {
                    setprojectExistsError(true);
                }
            });


        } else {
            setProjectNameError(true);
        }
    }

    useEffect( () => {
        setProjectName('');
        setProjectNameError(false);
        setprojectExistsError(false);
        setStartDate( new Date() );
    }, [modalStatus]);

    return(
        <>
            <button className="btn-transparent btn-inline" type="button" onClick={() => setModalStatus(true)}><AiOutlinePlus /></button>

            {modalStatus && 
                <Modal 
                    status={setModalStatus}
                    content={
                        <>
                            <div className="add-project-form">
                                <div className="form-item">
                                    <label>Название проекта:</label>
                                    <IconInput
                                        iconSize="20"                                    
                                        placeholder="Название проекта"
                                        icon={<BsFolder2Open size="20"/>}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        currentValue=""
                                        id="add-project-name"
                                    />
                                    { projectNameError && <div className="element-error">Укажите название проекта</div> }
                                    { projectExistsError && <div className="element-error">Такой проект уже существует</div> }
                                </div>

                                <div className="form-item">
                                    <label>Дата начала работ:</label>
                                    <DatePicker 
                                        dateFormat="dd MMM yyyy" 
                                        selected={startDate} 
                                        onChange={(date) => setStartDate( date )} 
                                    />
                                </div>

                            </div>
                        </>
                    }
                    title="Создать новый проект"
                    buttons={[
                        { 
                            title: "Создать",  
                            type: "btn-green", 
                            icon: <AiOutlinePlus />, 
                            on: ButtonAdd, 
                            id: 0 
                        }
                    ]}
                />
            
            }
        </>
    )
}

export default ButtonAddProject