import { RxReset }                  from "react-icons/rx";
import { useSelector }              from "react-redux";
import { useState, useEffect }      from "react";
import { BsFolder2Open }            from "react-icons/bs";
import Topbar                       from "../components/layout/TopBar"
import SideBar                      from "../components/layout/SideBar"
import PrintProject                 from "../components/elements/PrintProject";
import PrintProjectStatus           from "../components/elements/PrintProjectStatus";
import ButtonRemoveProject          from "../components/elements/projects/ButtonRemoveProject";
import IconInput                    from "../components/elements/IconInput";
import ButtonAddProject             from "../components/elements/projects/ButtonAddProject";
import Glass                        from "../images/wired-gradient-567-french-fries-chips-hover-pinch.json";
import Lottie                       from "lottie-react";
import ProjectStatusSelect          from "../components/elements/ProjectStatusSelect";
import PrintDateTimeNoHour          from "../components/elements/PrintDateTimeNoHour";




const Projects = () => {
    const userData                                  = useSelector(state => state.loginStore.userData);
    const loginToken                                = useSelector(state => state.loginStore.token);
    const apiSource                                 = useSelector(state => state.configStore.apiSource);
    const apiGetProjects                            = useSelector(state => state.configStore.apiGetProjects);
    const [projectItems, setProjectItems]           = useState([]);
    const [searchProject, setSearchProject]         = useState('');
    const [needToUpdate, setNeedToUpdate]           = useState(true);
    const [enabledViewTable, setEnabledViewTable]   = useState(false);
    const [filterStatus, setFilterStatus]           = useState('');

    useEffect( () => {
        setEnabledViewTable(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: loginToken, query: searchProject, statusID: filterStatus })
        };
        fetch(apiSource + apiGetProjects, requestOptions)
        .then(response => response.json())
        .then(data => {
            setProjectItems(data.projects);
            setNeedToUpdate(false);
            setEnabledViewTable(true);
        });
    }, [searchProject, needToUpdate, filterStatus]);

    const projectItemsMap = projectItems.map( item => 
        <div className="item" key={item.id}>
            <div><PrintProject status={item.status} name={item.name} /></div>
            <div><PrintProjectStatus data={{name: item.statusName, text: item.statusText, color: item.statusColor}} /></div>
            <div><PrintDateTimeNoHour value={item.startDateTime}/></div>
            { userData.userTypeID == '1' && (item.status == '4' || item.status == '1') && <div><ButtonRemoveProject data={item} needToUpdate={setNeedToUpdate} /></div> }
        </div>
    );

    const refreshButton = () => {
        setSearchProject('');
        setNeedToUpdate(true);
        setFilterStatus('');
    }

   
    return(
        <>
            <Topbar />
            <SideBar />
            <div className="work-area">
                <div className="top-area">
                    <div className="left">
                        <div className="header">Проекты</div>
                            <div>
                                <IconInput
                                    iconSize="20"                                    
                                    placeholder="название проекта..."
                                    icon={<BsFolder2Open size="20"/>}
                                    onChange={(e) => setSearchProject(e.target.value)}
                                    currentValue=""
                                    id="search-project"
                                />
                            </div>
                            <div>
                                <ProjectStatusSelect 
                                    iconSize="20"
                                    placeholder="статус"
                                    getValue={(id) => setFilterStatus(id)}
                                    value={filterStatus}
                                    currentValue=""
                                />
                            </div>
                        <div>
                            <button className="refresh-button" onClick={refreshButton}><RxReset /></button>
                        </div>    
                    </div>  
                    <div className="right">   
                        { userData.userTypeID == '1' &&
                            <ButtonAddProject needToUpdate={setNeedToUpdate} /> 
                        }  
                                  
                    </div>
                </div>

                {projectItems.length > 0 && 
                    <div className="content-area">
                        <div className="table-projects">
                            <div className="items">
                                <div className="item item-head">
                                    <div>Проект</div>
                                    <div>Последний статус</div>
                                    <div>Дата начала работ</div>
                                </div>
                                {projectItemsMap}
                            </div>
                        </div>
                    </div>
                }
                
                {projectItems.length == 0 &&
                    <div className="content-area">
                        <div className={enabledViewTable ? "period-no-project" : "display-none"}>
                            <div>
                                <div className="text">Тут пока ничего нет,</div>
                                <div className="text">но все можно исправить, со временем ...</div>
                                <div className="image">
                                    <Lottie animationData={Glass} loop={true} autoplay={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            
        </>
    )
    
}

export default Projects