import { createSlice } from "@reduxjs/toolkit";

const ConfigSlice = createSlice({
    name: "Configuration",

    initialState: {
        apiSource:                      '',
        apiIsAuthorizeUrl:              '/api/isAuthorize.php',
        apiAuthorizeUrl:                '/api/Authorize.php',
        apiGetActiveUsers:              '/api/getActiveUsers.php',
        apiGetProjectsLikeName:         '/api/getProjectsLikeName.php',
        apiGetProjects:                 '/api/getProjects.php',
        apiAddProject:                  '/api/addProject.php',
        apiRemoveProject:               '/api/removeProject.php',
        apiAddToPeriod:                 '/api/addToPeriod.php',
        apiGetPeriod:                   '/api/getPeriod.php',
        apiUpdatePeriod:                '/api/updatePeriod.php',
        apiCopyPeriod:                  '/api/copyPeriod.php',
        apiRemovePeriod:                '/api/removePeriod.php',
        apiAddToPayments:               '/api/addToPayments.php',
        apiAddPaymentComment:           '/api/addPaymentComment.php',
        apiRemovePaymentComment:        '/api/removePaymentComment.php',
        apiGetProjectStatuses:          '/api/getProjectStatuses.php',
        apiGetPayments:                 '/api/getPayments.php',
        apiDelPayment:                  '/api/delPayment.php',
        apiGetInteractions:             '/api/getInteractions.php',
        apiUpdateAddInteraction:        '/api/updateAddInteraction.php',
        apiGetCommentsIteraction:       '/api/getCommentsIteraction.php',
        apiRemoveCommentInteraction:    '/api/removeCommentInteraction.php',
        sideBarStatus:                  false     
    },
    reducers: {
      
        setIsAuthorizeUrl (state, action) {
            state.apiIsAuthorizeUrl = action.payload;
        },

        setSideBarStatus ( state, action ) {
            state.sideBarStatus = action.payload.sideBarStatus;
        }

    }

    
});


export const { setIsAuthorizeUrl, setSideBarStatus } = ConfigSlice.actions;
export default ConfigSlice.reducer;