import { RxCalendar } from "react-icons/rx";
import { AiOutlineClockCircle } from "react-icons/ai";

const PrintDateTime = ( props ) => {

    const DataValue = new Date(props.value);


    return(
        <div className="element-print-month">
            <div className="icon"><RxCalendar size={props.size} /></div>
            <div className="value">
                {DataValue.toLocaleString("ru", {day: '2-digit', month: 'short', year: 'numeric'})}
            </div>
            <div className="icon icon-time"><AiOutlineClockCircle size={props.size} /></div>
            <div className="value">
                {DataValue.toLocaleString("ru", {hour: '2-digit', minute: '2-digit'})}
            </div>
            
        </div>
    )
}

export default PrintDateTime