import { NavLink } from "react-router-dom";



import { useDispatch } from "react-redux";
import { setSideBarStatus } from "../../store/config";





const SideBarMenu = ( props ) => {

    const dispatch = useDispatch();

    const onLinkFollow = () => {
        dispatch(setSideBarStatus({sideBarStatus: false}));
    }

    const menuItems = props.links.map(item => 
        <NavLink to={item.to} onClick={onLinkFollow} key={item.key}>
            <div className="item">
                <div className="icon">{item.icon}</div>
                <div className="text">{item.text}</div>
            </div>
        </NavLink>
    );

    return(
        <div className="menu-items">
            {menuItems}
        </div>
    )
}

export default SideBarMenu