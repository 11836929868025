import { GoHourglass } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa6";
import { FaStop } from "react-icons/fa6";

const PrintProjectStatus = ( props ) => {
    
    let icon;
    let frameColor;

    switch(props.data.name) {
        case 'start':
            icon = <GoHourglass size="16" color={props.data.color} />;
            frameColor = props.data.color;
            break;
        
        case 'play':
            icon = <FaPlay size="12" color={props.data.color} />;
            frameColor = 'rgba(255, 255, 255, 0)';
            break;
        
        case 'pause':
            icon = <FaPause size="12" color={props.data.color} />;
            frameColor = props.data.color;
            break;
        
        case 'stop':
            icon = <FaStop size="12" color={props.data.color} />;
            frameColor = props.data.color;
            break;
    }

    return (
        <div className="element-status-item" style={{border: `1px solid ${frameColor}`}}>
            <div className="icon">{icon}</div>
            <div className="text">{props.data.text}</div>
        </div>
    )
}

export default PrintProjectStatus